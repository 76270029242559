<template>
  <validation-observer ref="simpleRules">
    <div class="row">
      <div class="col-12">
        <b-button
          class="btn btn-gradient-primary mb-1 mr-50"
          @click="$router.go(-1)"
        >
          <i class="fas fa-arrow-left mr-25" /> {{ $t('returnToList') }}
        </b-button>
        <b-button
          class="btn btn-gradient-success mb-1 ml-25 mr-50"
          @click="Submit()"
        >
          <i class="far fa-save mr-50" /> {{ $t('saveData') }}
        </b-button>
        <div>
          <div class="card fixed-card">
            <div class="d-flex justify-content-between align-items-center px-2">
              <div>
                <br>
                <h3
                  class="font-weight-bolder"
                >
                  {{ $t('addAnnouncement') }}
                </h3>
              </div>
            </div>
            <hr>
            <b-form-group
              label-for="h-parcel-code"
              label-cols="2"
              label-cols-lg="2"
              label-cols-md="2"
            >
              <template v-slot:label>
                <span class="text-danger">*</span> {{ $t('title') }}
              </template>
              <validation-provider
                #default="{ errors }"
                name="question-type"
                rules="required"
              >
                <b-form-input
                  v-model="postName"
                  class="col-8"
                  :state="errors.length > 0 ? false:null"
                />
                <span class="text-danger">{{ errors[0] ? $t('requiredField') : '' }}</span>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label-for="h-parcel-code"
              label-cols="2"
              label-cols-lg="2"
              label-cols-md="2"
            >
              <template v-slot:label>
                {{ $t('coverImage') }}
              </template>
              <div
                v-if="file_file"
                class="preview-image-container mr-25 d-flex justify align-items-center"
              >
                <img
                  :src="file_file"
                  alt="Preview"
                  width="100px"
                  height="50px"
                  @click="showPicture(file_file)"
                >
                <div class="preview-image-overlays p-0">
                  <i
                    class="fas fa-times-circle cursor-pointer"
                    @click="removeImage(file_file = null)"
                  />
                </div>
              </div>
              <div
                v-else
                class="input-file-container cursor-pointer"
              >
                <i class="fal fa-plus cursor-pointer mr-50" />{{ $t('key-9') }}
                <input
                  id="img-file"
                  ref="fileInput"
                  type="file"
                  class="input-file"
                  accept="image/*"
                  multiple
                  @change="handleFileChanges($event)"
                >
              </div>
            </b-form-group>

            <b-form-group
              label-for="h-parcel-code"
              label-cols="2"
              label-cols-lg="2"
              label-cols-md="2"
            >
              <template v-slot:label>
                {{ $t('activate') }}
              </template>
              <b-form-checkbox
                v-model="active"
                class="custom-control-success mt-50"
                switch
                :value="true"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
            <!-- <VEmojiPicker @select="selectEmoji" />
            <quill-editor
              v-model="content"
              :options="snowOption"
              class="p-2 fixed-edit"
            /> -->

            <quill-editor
              ref="quillEditor"
              v-model="content"
              class="p-2"
              :options="editorOption"
            >
              <div
                id="toolbar"
                slot="toolbar"
              >
                <span class="ql-formats">
                  <select class="ql-font" />
                  <select class="ql-size" />
                </span>
                <span class="ql-formats">
                  <button class="ql-bold" />
                  <button class="ql-italic" />
                  <button class="ql-underline" />
                  <button class="ql-strike" />
                </span>
                <span class="ql-formats">
                  <select class="ql-color" />
                  <select class="ql-background" />
                </span>
                <span class="ql-formats">
                  <button
                    class="ql-script"
                    value="sub"
                  />
                  <button
                    class="ql-script"
                    value="super"
                  />
                </span>
                <span class="ql-formats">
                  <button
                    class="ql-header"
                    value="1"
                  />
                  <button
                    class="ql-header"
                    value="2"
                  />
                  <button class="ql-blockquote" />
                  <button class="ql-code-block" />
                </span>
                <span class="ql-formats">
                  <button
                    class="ql-list"
                    value="ordered"
                  />
                  <button
                    class="ql-list"
                    value="bullet"
                  />
                  <button
                    class="ql-indent"
                    value="-1"
                  />
                  <button
                    class="ql-indent"
                    value="+1"
                  />
                </span>
                <span class="ql-formats">
                  <button
                    class="ql-direction"
                    value="rtl"
                  />
                  <select class="ql-align" />
                </span>
                <span class="ql-formats">
                  <button class="ql-link" />
                  <button class="ql-image" />
                  <button class="ql-video" />
                  <button class="ql-formula" />
                </span>
                <span class="ql-formats">
                  <button class="ql-clean" />
                </span>
                <b-dropdown
                  text="😃"
                  variant="light"
                >
                  <VEmojiPicker
                    @select="selectEmoji"
                  />
                </b-dropdown>
              </div>
            </quill-editor>
          </div>
        </div>
      </div>
    </div>

    <vue-image-lightbox-carousel
      ref="lightbox"
      :show="showLightbox"
      :images="images"
      :show-caption="false"
      @close="closeBox()"
    />
  </validation-observer>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
  BDropdown,
} from 'bootstrap-vue'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line
import { VEmojiPicker } from 'v-emoji-picker'

import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'

import { required } from '@validations'

export default {
  components: {
    quillEditor,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    ValidationProvider,
    ValidationObserver,
    VEmojiPicker,
    BDropdown,
    VueImageLightboxCarousel,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      required,
      content: '',
      postName: null,
      file_file: null,
      active: false,
      // editor: null,
      editorOption: {
        modules: {
          toolbar: '#toolbar',
        },
        placeholder: 'message...',
      },

    }
  },
  computed: {
    editor() {
      return this.$refs.quillEditor.quill
    },
  },
  mounted() {

  },
  methods: {
    selectEmoji(dataEmoji) {
      const cursor = this.editor.getSelection()
      if (cursor) {
        if (cursor.length <= 0) {
          this.editor.insertText(this.editor.getSelection(), dataEmoji.data)
        } else {
          this.editor.setText(dataEmoji.data)
        }
      } else {
        const range = this.editor.getText().length - 1
        this.editor.insertText(range, dataEmoji.data)
      }
    },
    Submit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const obj = {
            content: this.content,
            postName: this.postName,
            file_file: this.file_file,
            active: this.active,
          }
          this.$http.post('/Notice/StoreNotice/data', obj)
            .then(() => {
              this.Success(this.$t('key-215'))
              this.$router.push({ name: 'admin-notice' })
            })
            .catch(err => {
              console.log(err)
              this.SwalError(err)
            })
        }
      })
      // console.log(this.content)
    },
    handleFileChanges(event) {
      const filescus = event.target.files
      const reader = new FileReader()
      const filepath = filescus[0]
      reader.onload = e => {
        // console.log(e.target.result)
        // console.log(e.target.result)
        this.file_file = e.target.result
        // console.log(this.filecusimg)
        // e.target.result
        // console.log(this.namemap)
      }
      reader.readAsDataURL(filepath)
    },
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: picture }
      this.images.push(img)
      // this.$swal({
      //   imageUrl: picture,
      //   imageWidth: 400,
      //   imageHeight: 400,
      //   imageAlt: 'Custom image',
      //   confirmButtonText: this.$t('key-214'),
      // })
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
